import { firestore } from '@/helpers/firestore'
import * as Sentry from '@sentry/browser'
import { DateTimeHelper } from '@appsocially/timepassport'
import * as ics from 'ics'

export async function getInterviews (uid) {
  try {
    const snapshot = await firestore
      .collection('OWNERS')
      .doc(uid)
      .collection('SCENARIO_PUBLIC')
      .doc(process.env.VUE_APP_RECRUIT_SCENARIO_ID)
      .collection('INTERVIEWS')
      .where('state', '==', 'ACTIVE')
      .get()
    return snapshot.docs.map(doc => doc.data())
  } catch (err) {
    // eslint-disable-next-line
    console.error("Error: ", err);
    Sentry.captureException(err)
  }
}

export async function updateResponse (uid, attendeeId, data) {
  await firestore
    .collection('OWNERS')
    .doc(uid)
    .collection('SCENARIO_PUBLIC')
    .doc(process.env.VUE_APP_RECRUIT_SCENARIO_ID)
    .collection('RESPONSES')
    .doc(attendeeId)
    .set(data, { merge: true })
}

export function getApplicantData (uid, id) {
  return firestore
    .collection('OWNERS')
    .doc(uid)
    .collection('SCENARIO_PUBLIC')
    .doc(process.env.VUE_APP_RECRUIT_SCENARIO_ID)
    .collection('RESPONSES')
    .doc(id)
    .get()
    .then(doc => doc.data()).catch(err => {
      // eslint-disable-next-line
      console.error("Error: ", err);
      Sentry.captureException(err)
    })
}

export function calculateStartInterval (events) {
  let start = 8
  events.forEach(event => {
    const hour = DateTimeHelper.parse(event.start).getHours()
    if (hour < start) {
      start = hour
    }
  })
  return start - 1
}

export function calculateEndInterval (events) {
  let end = 20
  events.forEach(event => {
    const hour = DateTimeHelper.parse(event.start).getHours()
    if (hour > end) {
      end = hour
    }
  })
  return end + 1
}

export function checkOverlap ({ start, end, events }) {
  // FIXME: SHOULD NOT CHECK OVERLAP USING STRING!!!!!!!!!!!!!!!
  const date = start.split(' ')[0]

  const overlap = events.filter(event => {
    return event.start.split(' ')[0] === date
  }).some(event => {
    return DateTimeHelper.checkWithinTimeRange(event.start, event.end, start, end)
  })

  return overlap
}

export async function convertEventToIcs (event) {
  return new Promise((resolve, reject) => {
    return ics.createEvent(event, (error, value) => {
      if (error) {
        reject(error)
        return
      }
      resolve(value)
    })
  })
}
