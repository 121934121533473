<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <h3 class="headline mb-0">Add to Calendar</h3>
      </div>
    </v-card-title>
    <v-list v-if="showInterviewDetail">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("m.cancelInterview.companyName")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ companyNameJa }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("m.cancelInterview.applicantName")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ applicantName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("m.cancelInterview.date")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ date }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("m.cancelInterview.time")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ time }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list :disabled="false">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :href="links[item.link]"
        :download="item.ics ? 'calendar.ics' : undefined"
        target="_blank"
      >
        <v-list-item-avatar>
          <v-img :src="item.icon"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { differenceInMinutes, differenceInHours } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { stringify } from 'qs'
import { convertEventToIcs } from '@/helpers/calendar'
import jaLocale from 'date-fns/locale/ja'

// http://localhost:8080/add-to-calendar/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcm9tIjoib3duZXIiLCJvd25lcklkIjoiWllKVXdNbWFJM01rTE03MzB6SVFtMVJyTmtsMiIsIm93bmVyU2NlbmFyaW9JZCI6Ikk1MGVvbUVYWkZJNUd4Z0M3a1hrIiwic2F2ZWRJbnRlcnZpZXdJZCI6IndaNmpwMjJiMmtjTUJzMGlNd0NZIiwiYXBwbGljYW50SWQiOiJEYXptRldJY2FqcVo0RmFybUgxZyIsImlhdCI6MTU5ODg0MDM3NH0.Iu8qe4JbM9-VTqsg1VGNiqaNSJ-qyf4rnjSKf2CSEkc

export default {
  components: {
  },
  props: {
    showInterviewDetail: {
      type: Boolean,
      default: false
    },
    interview: {
      type: Object,
      required: true
    },
    scheduledSlot: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      icsLink: null,
      loading: true,
      text: {},
      items: [{
        title: 'm.addToCalendar.googleCalendar',
        icon: '/images/logos/google_calendar.png',
        link: 'googleCalendar'
      }, {
        title: 'm.addToCalendar.outlookCalendar',
        icon: '/images/logos/outlook_calendar.png',
        link: 'outlookCalendar',
        ics: true
      }, {
        title: 'm.addToCalendar.yahooCalendar',
        icon: '/images/logos/yahoo.png',
        link: 'yahooCalendar'
      }, {
        title: 'm.addToCalendar.appleCalendar',
        icon: '/images/logos/apple_calendar.png',
        link: 'appleCalendar',
        ics: true
      }]
    }
  },
  methods: {
    getSimpleDataFromInterview () {
      const applicantName = this.scheduledSlot.applicantName
      const slotLink = `${process.env.VUE_APP_BASE_URL}/calendar?slot=${this.interview.schedulerlySlotId}`

      return {
        from: new Date(this.interview.startTimestamp),
        to: new Date(this.interview.endTimestamp),
        fromUtc: utcToZonedTime(new Date(this.interview.startTimestamp), 'UTC'),
        toUtc: utcToZonedTime(new Date(this.interview.endTimestamp), 'UTC'),
        title: this.$t('m.addToCalendar.title', { name: applicantName }),
        details: this.$t('m.addToCalendar.detail', { link: slotLink }),
        slotLink,
        applicantName
      }
    },
    async updateIcsLink (initialConfirm) {
      // http://localhost:8080/add-to-calendar/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcm9tIjoib3duZXIiLCJvd25lcklkIjoiWllKVXdNbWFJM01rTE03MzB6SVFtMVJyTmtsMiIsIm93bmVyU2NlbmFyaW9JZCI6Ikk1MGVvbUVYWkZJNUd4Z0M3a1hrIiwic2F2ZWRJbnRlcnZpZXdJZCI6IndaNmpwMjJiMmtjTUJzMGlNd0NZIiwiYXBwbGljYW50SWQiOiJEYXptRldJY2FqcVo0RmFybUgxZyIsImlhdCI6MTU5ODg0MDM3NH0.Iu8qe4JbM9-VTqsg1VGNiqaNSJ-qyf4rnjSKf2CSEkc
      const { from, to, title, details, slotLink } = this.getSimpleDataFromInterview()
      convertEventToIcs({
        start: [from.getFullYear(), from.getMonth() + 1, from.getDate(), from.getHours(), from.getMinutes()],
        duration: { hours: differenceInHours(to, from), minutes: differenceInMinutes(to, from) % 60 },
        title,
        description: details,
        url: slotLink,
        categories: ['Interviews'],
        status: 'CONFIRMED',
        busyStatus: 'BUSY'
      }).then((text) => {
        var data = new Blob([text], { type: 'text/calendar' })
        this.icsLink = window.URL.createObjectURL(data)
      })
    }
  },
  computed: {
    date () {
      const { from } = this.getSimpleDataFromInterview()
      return format(from, this.$t('m.addToCalendar.dateFormat'), { locale: jaLocale })
    },
    time () {
      const { from } = this.getSimpleDataFromInterview()
      return format(from, this.$t('m.addToCalendar.timeFormat'), { locale: jaLocale })
    },
    companyNameJa () {
      return this.scheduledSlot.companyNameJa
    },
    applicantName () {
      return this.scheduledSlot.applicantName
    },
    googleLink: function () {
      const { fromUtc, toUtc, title, details } = this.getSimpleDataFromInterview()

      const dateFormatStr = 'yyyyMMdd\'T\'HHmmssX'
      const fromText = format(fromUtc, dateFormatStr, { timeZone: 'UTC' })
      const toText = format(toUtc, dateFormatStr, { timeZone: 'UTC' })

      const googleQuery = stringify({
        action: 'TEMPLATE',
        text: title,
        details: details,
        dates: `${fromText}/${toText}`
      })

      return `https://calendar.google.com/calendar/render?${googleQuery}`
    },
    yahooLink: function () {
      const { fromUtc, toUtc, title, details } = this.getSimpleDataFromInterview()
      const dateFormatStr = 'yyyyMMdd\'T\'HHmmssX'
      const fromText = format(fromUtc, dateFormatStr, { timeZone: 'UTC' })
      const duration = `${(differenceInHours(toUtc, fromUtc) + '').padStart(2, '0')}${(differenceInMinutes(toUtc, fromUtc) % 60 + '').padStart(2, '0')}`
      const yahooQuery = stringify({
        v: 60,
        view: 'd',
        type: '20',
        title,
        st: fromText,
        desc: details,
        dur: duration
      })

      return `https://calendar.yahoo.com/?${yahooQuery}`
    },
    links: function () {
      if (!this.scheduledSlot || !this.interview) {
        return {}
      }

      return {
        googleCalendar: this.googleLink,
        yahooCalendar: this.yahooLink,
        appleCalendar: this.icsLink,
        outlookCalendar: this.icsLink
      }
    }
  },
  async mounted () {
    this.updateIcsLink()
  }
}
</script>

<style>
</style>
